/*----------------------------------------*/
/*  10. MENU CSS START
/*----------------------------------------*/


.main-menu {
  text-align: right;

  @media #{$lg} {
    display: none;
  }

  ul {
    li {
      display: inline-block;
      padding: 0 20px;

      @media #{$xl} {
        padding: 0 15px;
      }

      &:hover {
        .main-dropdown {
          top: 80px;
          opacity: 1;
          visibility: visible;
        }

        .mega-menu {
          top: 80px;
          opacity: 1;
          visibility: visible;
        }
      }

      a {
        font-weight: 600;
        line-height: 1.3;
        display: block;
        color: var(--black-2);
        font-size: 16px;
        padding: 30px 8px;
        text-transform: capitalize;

        &:hover {
          color: var(--primary);
        }
      }
    }
  }

  .main {
    &-dropdown {
      color: var(--white);
      position: absolute;
      width: 260px;
      top: 100px;
      background: var(--blue);
      padding: 20px 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

      li {
        padding: 0 30px;
        display: block;
        text-align: left;
        position: relative;

        &:hover {
          .sub-dropdown {
            left: 100%;
            opacity: 1;
            visibility: visible;

            @media #{$xxl} {
              left: -100%;
            }
          }
        }

        a {
          padding: 10px 0;
          color: var(--white);
        }
      }
    }
  }

  .sub {
    &-dropdown {
      position: absolute;
      width: 260px;
      left: 90%;
      top: 0;
      background: var(--blue);
      padding: 20px 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

      @media #{$xxl} {
        left: -90%;
      }

      li {
        padding: 0 30px;
        display: block;
        text-align: left;
        position: relative;

        a {
          padding: 10px 0;
        }
      }
    }
  }

  .has-dropdown {
    position: relative;

    &:hover {
      &::after {
        color: var(--primary);
      }
    }

    &::after {
      position: absolute;
      content: "\f107";
      width: 15px;
      height: 15px;
      top: 30px;
      color: var(--black-2);
      font-family: "Font Awesome 6 Free";
      font-weight: 700;
      transition: all 0.2s;
    }
  }

  .has-megamenu {
    position: static;

    &:hover {
      &::after {
        color: var(--primary);
      }
    }

    &::after {
      position: absolute;
      content: "\f107";
      width: 15px;
      height: 15px;
      top: 30px;
      color: var(--black-2);
      font-family: "Font Awesome 6 Free";
      font-weight: 700;
      transition: all 0.2s;
    }
  }

  .main-dropdown .has-dropdown {
    position: relative;

    &:hover {
      &::after {
        color: var(--primary);
      }
    }

    &::after {
      position: absolute;
      content: "\f107";
      width: 15px;
      height: 15px;
      top: 15px;
      right: 20px;
      color: var(--white);
      transform: rotate(-90deg);
      font-family: "Font Awesome 6 Free";
      font-weight: 700;
      transition: all 0.2s;
    }
  }


  // Mega Menu
  .mega-menu {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100px;
    z-index: 9;
    background-color: var(--blue);
    text-align: left;
    padding: 40px 230px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

    @media #{$xxl} {
      padding: 40px 50px;
    }

    @media #{$xl} {
      padding: 40px 20px;
    }

    li {
      display: block;

      &:first-child {
        font-size: 18px;
        color: var(--white);
        padding-bottom: 10px;
        text-transform: capitalize;
      }

      a {
        padding: 8px 5px;
        color: var(--white);
      }
    }
  }

}