.testimonial {
  &__area {
    &-3 {
      background-color: var(--secondary);
    }
  }

  &__left {
    &-3 {
      p {
        color: var(--white);
        padding-bottom: 40px;
      }

      a {
        color: var(--white);
        border-color: var(--white);

        &:hover {
          color: var(--blue);
          border-color: currentColor;
        }

        i {
          color: var(--blue);
          transition: all 0.3s;
        }
      }
    }
  }

  &__right {
    &-3 {
      position: relative;
      margin-left: 20px;

      @media #{$md} {
        margin-left: 0;
      }
    }
  }

  &__slider {
    &-3 {
      padding: 150px 45px 90px;
      text-align: center;
      background-color: var(--blue);
      background-repeat: no-repeat;
      background-position: center 100px;
      // background-image: url(../imgs/testimonial/3/quote.png);
      background-image: url("~/public/assets/imgs/testimonial/3/quote.png");
      position: absolute;
      right: -60px;
      width: 100%;

      @media #{$lg} {
        padding: 90px 30px 60px;
        right: -20px;
      }

      @media #{$md} {
        width: 105%;
        padding: 80px 20px 50px;
      }

      @media #{$sm} {
        position: unset;
        margin-top: 40px;
        width: 100%;
        padding: 50px 20px;
      }
    }
  }

  &__slide {
    &-3 {
      p {
        color: var(--white);
        padding-bottom: 100px;

        @media #{$lg} {
          padding-bottom: 60px;
        }

        @media #{$sm} {
          padding-bottom: 40px;
        }
      }
    }
  }

  &__content {
    &-3 {
      gap: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      .img {
        img {
          width: 80px;
          height: 80px;
          border-radius: 100%;
          border: 1px solid var(--yellow);
        }
      }

      .info {
        text-align: left;
      }

      .rating {
        gap: 3px;
        display: flex;
        margin-top: 5px;

        li {
          font-size: 14px;
          color: var(--yellow);
        }
      }

    }
  }
}

.client {
  &__name {
    &-3 {
      font-size: 20px;
      line-height: 1.2;
      font-weight: 500;
      color: var(--white);
      padding-bottom: 5px;
    }
  }

  &__role {
    &-3 {
      font-size: 16px;
      line-height: 1.2;
      font-weight: 400;
      color: var(--white-2);
    }
  }
}