.team {
  &__area {
    padding-bottom: 300px;
    // background-image: url(../imgs/shape/zigzag-2.png);
    background-image: url("~/public/assets/imgs/shape/zigzag-2.png");
    background-position: bottom left;
    background-repeat: no-repeat;
    overflow: hidden;

    @media #{$lg} {
      padding-bottom: 200px;
    }

    @media #{$md} {
      padding-bottom: 90px;
    }

    @media #{$sm} {
      padding-bottom: 50px;
    }

    .sec-subtitle {
      margin-bottom: 40px;
    }

    &-3 {
      position: relative;
      padding-top: 260px;

      @media #{$lg} {
        padding-top: 200px;
      }

      @media #{$md} {
        padding-top: 150px;
      }

      @media #{$sm} {
        padding-top: 60px;
      }

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 500px;
        bottom: 0;
        left: 0;
        background-color: var(--gray-2);

        @media #{$xxl} {
          height: 200px;
        }
      }

      .sec-title-wrap {
        padding-bottom: 35px;

        @media #{$sm} {
          padding-bottom: 20px;
        }
      }

      .plr-100 {
        @media #{$xxl} {
          padding-left: 30px;
          padding-right: 30px;
        }

        @media #{$sm} {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }

  &__list {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 40px;

    @media #{$md} {
      grid-gap: 15px;
    }

    @media #{$sm} {
      grid-gap: 40px;
      grid-template-columns: 1fr;
    }
  }

  &__member {
    &:nth-child(2) {
      padding-top: 40px;

      @media #{$sm} {
        padding-top: 0;
      }
    }

    &:nth-child(3) {
      padding-top: 80px;

      @media #{$sm} {
        padding-top: 0;
      }
    }

    &:hover .team__content {
      background-color: var(--primary);

      p {
        opacity: 1;
      }
    }
  }

  &__image {
    margin-left: 35px;
    position: relative;
    overflow: hidden;

    img {
      transition: all 0.5s;
    }

    &:hover img {
      transform: scale(1.1);
    }

    @media #{$lg} {
      margin-left: 25px;
    }

    &-3 {
      overflow: hidden;

      img {
        width: 100%;
        transition: all 0.5s;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  &__content {
    max-width: 82%;
    margin-top: -70px;
    padding: 110px 60px 40px 60px;
    background-color: var(--gray-2);
    transition: all 0.5s;

    @media #{$xl} {
      max-width: 90%;
      padding: 100px 40px 40px;
    }

    @media #{$lg} {
      max-width: 95%;
      padding: 90px 30px 40px;
    }

    @media #{$md} {
      padding: 90px 20px 30px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      opacity: 0.6;
      color: var(--black-2);
      padding-top: 10px;
    }

    &-3 {
      text-align: center;
      padding: 0 30px 25px;
      background: var(--white);
      margin-top: -50px;
      margin-left: 40px;
      margin-right: 40px;
      position: relative;
      z-index: 1;

      @media #{$xxl} {
        margin-left: 15px;
        margin-right: 15px;
      }

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.9;
        color: var(--black-2);
      }

      .social-media {
        gap: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(-50%);

        li {
          a {
            width: 50px;
            height: 50px;
            border-radius: 5px;
            display: inline-block;
            background-color: var(--gray-2);
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--blue);

            &:hover {
              color: var(--primary);
            }
          }
        }
      }
    }
  }

  &__title {
    font-size: 32px;
    line-height: 1;
    font-weight: 600;
    color: var(--black-2);

    @media #{$lg} {
      font-size: 30px;
    }

    @media #{$md} {
      font-size: 24px;
    }

    &-3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.3;
      color: var(--black-2);
      transition: all 0.3s;

      &:hover {
        color: var(--primary);
      }
    }
  }

  &__awards {
    text-align: right;
  }

  &__award {
    font-weight: 700;
    font-size: 128px;
    line-height: 0.8;
    text-transform: uppercase;
    opacity: 0.2;
    -webkit-text-stroke: 1px var(--black-2);
    color: transparent;

    @media #{$sm} {
      font-size: 80px;
    }
  }
}


.share {
  &__wrap {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 30px;
    padding-left: 30px;

    li {
      position: absolute;
      top: 90px;
      transition: all 0.5s;
      transform: translateY(0);
      opacity: 0;
      visibility: hidden;
      z-index: 9;

      a {
        width: 50px;
        height: 50px;
        display: inline-block;
        background-color: var(--white);
        text-align: center;
        line-height: 50px;
        font-size: 18px;
        color: var(--black-2);

        &:hover {
          background-color: var(--primary);
        }
      }
    }

    .active {
      li {
        top: 30px;
        opacity: 1;
        visibility: visible;
        transform: translateY(calc(60px * var(--i)));
      }
    }

    .share-btn {
      width: 50px;
      height: 50px;
      display: inline-block;
      background-color: var(--primary);
      text-align: center;
      line-height: 50px;
      font-size: 18px;
      color: var(--black-2);
      border: none;
      outline: none;
      margin-bottom: 10px;
    }
  }
}