/*----------------------------------------*/
/*  . BLOG CSS START
/*----------------------------------------*/

.blog {
  &__item {
    &-inner {
      margin-bottom: 80px;

      @media #{$sm} {
        margin-bottom: 30px;
      }
    }
  }

  &__thumb {
    &-inner {
      overflow: hidden;

      img {
        transition: 1s;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  &__meta {
    &-inner {
      display: flex;
      gap: 50px;
      padding-top: 10px;
      padding-bottom: 20px;

      @media #{$sm} {
        flex-wrap: wrap;
        gap: 10px;
        padding-bottom: 10px;
      }

      li {
        a {
          font-weight: 500;
          font-size: 16px;
          line-height: 2;
          color: var(--black-4);
        }
      }
    }
  }

  &__title {
    &-inner {
      font-weight: 600;
      font-size: 48px;
      line-height: 1;
      letter-spacing: 0.16px;
      text-transform: uppercase;
      color: #0e1e2a;
      padding-bottom: 15px;
      transition: 0.3s;

      &:hover {
        color: var(--primary);
      }

      @media #{$xl} {
        font-size: 35px;
      }

      @media #{$sm} {
        font-size: 25px;
      }
    }
  }

  &__dis {
    &-inner {
      font-weight: 500;
      font-size: 16px;
      line-height: 2;
      color: #67687a;
      padding-bottom: 40px;

      @media #{$sm} {
        line-height: 1.5;

        br {
          display: none;
        }
      }
    }
  }

  &__btn {
    &-inner {
      display: inline-block;
      padding: 15px 30px;
      font-weight: 600;
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 1.6px;
      text-transform: uppercase;
      color: #ffffff;
      background: var(--blue-2);

      &::before {
        border-radius: 0;
      }

      &.btn-rollover {
        border-radius: 0;
      }

      &:hover {
        color: #ffffff;
      }

      i {
        padding-left: 5px;
      }
    }
  }

  &__search {
    form {
      position: relative;

      input {
        width: 100%;
        background: #f4f5fa;
        border: 1px solid #e3e3e3;
        border-radius: 5px;
        height: 61px;
        padding: 30px;
        padding-right: 40px;

        &:focus {
          outline: 0;
        }

        &::placeholder {
          font-weight: 500;
          font-size: 16px;
          line-height: 1;
          color: #67687a;
        }
      }

      button {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 900;
        font-size: 16px;
        line-height: 1;
        color: #d8ff36;
        border: 0;
        background: transparent;
      }
    }
  }

  &__widget {
    margin-bottom: 60px;

    &-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.2;
      color: #0e1e2a;
      border-bottom: 1px solid #e3e3e3;
      display: block;
      padding-bottom: 30px;
    }
  }

  &__category {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;

      &:last-child {
        padding-bottom: 0;
      }

      span {
        font-weight: 600;
      }

      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 1;
        color: #0e1e2a;
        font-weight: 600;

        &:hover {
          color: var(--primary);
        }
      }
    }
  }

  &__ritem {
    display: grid;
    grid-template-columns: auto 80px;
    gap: 75px;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__recenttitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2;
    color: #0e1e2a;
    padding-bottom: 10px;
  }

  &__tags {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 1;
      color: #67687a;
      display: inline-block;
      padding: 10px 16px;
      background: #f4f5fa;
      border-radius: 5px;
      transition: 0.3s;

      &:hover {
        background: var(--primary);
      }
    }
  }

  &__notification {
    background: #f4f5fa;
    padding: 70px;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    p {
      text-align: center;
      padding-top: 10px;
      padding-bottom: 20px;
    }
  }

  &__pagination {
    &-wrap {
      padding-left: 260px;

      @media #{$md} {
        padding-left: 15px;
      }
    }

    &-list {
      display: flex;
      gap: 20px;

      li {
        a {
          font-weight: 600;
          font-size: 20px;
          line-height: 1.2;
          text-align: center;
          color: #0e1e2a;
          display: inline-block;
          background: #f4f5fa;
          border-radius: 5px;
          padding: 10px 20px;

          &:hover {
            background: var(--blue-2);
            color: #ffff;
          }
        }
      }
    }
  }

  &__details {
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 2;
      color: #67687a;
      padding-bottom: 20px;
    }

    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.2;
      color: #0e1e2a;
      padding-bottom: 20px;
      transition: 0.3s;

      &:hover {
        color: var(--primary);
      }
    }
  }

  &__quote {
    padding: 40px;
    background: #f4f5fa;
    border-radius: 5px;

    @media #{$sm} {
      padding: 15px;
    }
  }

  &__thumb2 {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  &__detaillist {
    display: flex;
    gap: 30px;

    span {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.2;
      color: #0e1e2a;
    }
  }

  &__detailtag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e3e3e3;
    padding-top: 15px;
    margin-top: 60px;

    @media #{$sm} {
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 30px;
    }
  }

  &__detailleft {
    display: flex;
    gap: 30px;
    align-items: center;
  }

  &__social {
    display: flex;
    gap: 10px;
    align-items: center;

    li {
      a {
        font-weight: 400;
        font-size: 16px;
        line-height: 1;
        color: #d8ff36;
        padding: 10px 10px;
        background: #f4f5fa;
        border-radius: 5px;
        display: inline-block;
      }
    }
  }

  &__submitbtn {
    display: inline-block;
    padding: 20px 30px;
    background: #d8ff36;
    border-radius: 5px;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    color: #ffffff;
    border: 0;
  }

  &__form {
    padding-top: 90px;

    @media #{$sm} {
      padding-top: 30px;
    }

    form {
      padding-top: 30px;
    }

    &-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.4;
      color: #0e1e2a;
      display: block;
    }

    input {
      width: 100%;
      padding: 20px;
      border: 1px solid #e3e3e3;
      border-radius: 5px;
      height: 51px;
      margin-bottom: 20px;

      &:focus {
        outline: 0;
      }
    }

    textarea {
      height: 180px;
      width: 100%;
      padding: 20px;
      border: 1px solid #e3e3e3;
      border-radius: 5px;
      margin-bottom: 20px;

      &:focus {
        outline: 0;
      }
    }
  }

  &__submitwrap {
    text-align: center;

    @media #{$md} {
      margin-bottom: 30px;
    }
  }
}

/*----------------------------------------*/
/*  . PROJECT DETAILS CSS START
/*----------------------------------------*/
.projectd {
  &__details {
    &-inner {
      h2 {
        font-size: 48px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: 0.16px;
        text-transform: uppercase;
        padding-bottom: 20px;

        @media #{$md} {
          font-size: 35px;
        }
      }

      h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.5;
        padding-bottom: 15px;
        padding-top: 10px;
      }

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 2;
        padding-bottom: 20px;
        color: var(--black-4);
      }
    }
  }

  &__item {
    &-inner {
      display: grid;
      grid-template-columns: 40px auto;
      max-width: 185px;
      gap: 23px;
      align-items: center;
    }
  }

  &__mcontent {
    &-inner {
      .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        padding-bottom: 15px;
      }
    }
  }

  &__meta {
    &-inner {
      display: flex;
      gap: 160px;
      padding: 45px 40px;
      background: #f4f5fa;
      margin-bottom: 70px;

      @media #{$lg} {
        gap: 50px;
      }

      @media #{$md} {
        gap: 30px;
        margin-bottom: 50px;
        flex-wrap: wrap;
      }

      @media #{$sm} {
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        margin-bottom: 20px;
      }
    }
  }

  &__checkboxwrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    margin-bottom: 40px;
    margin-top: 10px;

    @media #{$md} {
      gap: 0;
    }

    @media #{$sm} {
      grid-template-columns: 1fr;
    }
  }

  &__checkbox {
    label {
      padding-left: 15px;
      display: inline-block;
      font-weight: 600;
    }
  }

  &__detailsthumb {
    gap: 30px;
    padding-top: 20px;
    padding-bottom: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    img {
      width: 100%;
    }
  }
}

/*----------------------------------------*/
/*  . CONTACT CSS START
/*----------------------------------------*/

.contact {
  &__item {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    background: #f4f5fa;
    border-radius: 5px;
    padding: 45px;
    margin-bottom: 40px;

    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.2;
      text-align: center;
      color: #333f4d;
      padding-top: 20px;
      padding-bottom: 15px;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 2;
      color: #67687a;
    }

    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 2;
      color: #67687a;
    }
  }

  &__submit {
    display: inline-block;
    padding: 20px 50px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    color: #ffffff;
    border: 0;
    background: #064bb5;
    border-radius: 5px;
  }
}

.pt-30 {
  padding-top: 30px;
}
