/*----------------------------------------*/
/*  08. ANIMATION CSS START
/*----------------------------------------*/


@keyframes dbRotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes cxuRipple {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}


@keyframes moveBottom {
  0% {
    transform: translateX(-25px);
  }

  50% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-25px);
  }
}

@keyframes moveRight {
  0% {
    transform: translateX(25px);
  }

  50% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(25px);
  }
}

.moveRight {
  animation: moveRight 2s 1;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@keyframes wcLoading {
  0% {
    transform: scaleY(0.1);
    background: var(--white);
  }

  50% {
    transform: scaleY(1);
    background: var(--primary);
  }

  100% {
    transform: scaleY(0.1);
    background: transparent;
  }
}


// Scroll
@keyframes scroll {
  from {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(calc(-100% - 20px));
    -ms-transform: translateX(calc(-100% - 20px));
    transform: translateX(calc(-100% - 20px));
  }
}


// Fade Up
@keyframes cxufadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.cxufadeUp {
  opacity: 0;
  -webkit-animation-name: cxufadeUp;
  animation-name: cxufadeUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.cxufadeUp2 {
  @extend .cxufadeUp;
  animation-delay: 0.3s;
}

.cxufadeUp3 {
  @extend .cxufadeUp;
  animation-delay: 0.45s;
}

.cxufadeUp4 {
  @extend .cxufadeUp;
  animation-delay: 0.6s;
}


// Spin
@-webkit-keyframes cxuSpin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes cxuSpin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.cxuSpin-slow {
  animation: cxuSpin 5s linear infinite;
}

.cxuSpin-medium {
  animation: cxuSpin 3s linear infinite;
}

.cxuSpin-fast {
  animation: cxuSpin 2s linear infinite;
}



/* Shine */
.shine {
  position: relative;
  overflow: hidden;
}

.shine::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.shine:hover::before {
  -webkit-animation: shine .75s;
  animation: shine .75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}