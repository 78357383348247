/*----------------------------------------*/
/* 05. BUTTON CSS START
/*----------------------------------------*/

.btn-rollover {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: var(--white);
  padding: 14px 32px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;

  &::before {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 0px;
    z-index: -1;
    content: '';
    border-radius: 6px;
    background: var(--primary);
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1) 0s;
  }

  &:hover {
    color: var(--white);
    background-color: transparent;

    &::before {
      left: 0%;
      right: auto;
      width: 100%;
    }
  }
}


.db-btn {
  &-arrow {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    color: var(--black-2);
    display: inline-block;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      left: 0;
      bottom: -8px;
      transition: all 0.3s;
      background-color: var(--black-2);
    }

    i {
      font-size: 18px;
      color: var(--primary);
      transform: rotate(-45deg);
    }

    &:hover {
      &::after {
        width: 0;
      }

      i {
        color: var(--black-2);
        transition: all 0.3s;
      }
    }
  }

  &-border {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 600;
    padding: 16px 22px;
    display: inline-block;
    letter-spacing: 1.6px;
    color: var(--black-2);
    text-transform: uppercase;
    border: 1px solid currentColor;

    &::before {
      border-radius: 0;
    }

    &.btn-rollover {
      border-radius: 0;
    }

    &.color-1 {
      &:hover {
        color: var(--black-2);
      }
    }

    i {
      transform: rotate(-45deg);
    }
  }

  &-call {
    font-size: 20px;
    line-height: 1.3;
    font-weight: 600;
    color: var(--black-2);
    display: inline-block;

    span {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      display: inline-block;
      box-shadow: 0px 4.8px 24.4px rgba(19, 16, 34, 0.1), 0px 4px 13px rgba(19, 16, 34, 0.06);
      text-align: center;
      line-height: 50px;
      color: var(--blue);
      font-size: 16px;
    }
  }

  &-primary {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5;
    padding: 24px 50px;
    letter-spacing: 1.6px;
    display: inline-block;
    color: var(--secondary);
    text-transform: uppercase;
    background-color: var(--yellow);

    &:hover {
      color: var(--secondary);
      background-color: var(--primary);
    }
  }


}

.db-arrow-up {
  width: 120px;
  height: 120px;
  overflow: hidden;
  transition: all 0.5s;

  img {
    transition: all 0.5s;
  }

  .img-2 {
    transform: translate(-120px, 10px);
  }

  &:hover {
    .img {
      transform: translate(120px, -120px);
    }

    .img-2 {
      transform: translate(0px, -106px);
    }
  }
}